import styles from "./IframeSection.module.scss";

export interface IframeSectionProps {
  src: string;
  "data-testid"?: string;
}

export function IframeSection({ src, "data-testid": dataTestId }: IframeSectionProps) {
  return (
    <iframe
      src={src}
      title="YouTube video player"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      className={styles.iframe}
      data-testid={dataTestId}
    ></iframe>
  );
}
